import { Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import Account from "../../pages/account/Account";
import React, { useEffect } from "react";
import { isMobile, recordEvent } from "../../util/utils";

interface CheckoutModalProps {
    isOpen: boolean;
    toggleModal: () => void;
    userEmail: string|null;
}

export const CheckoutModal: React.FC<CheckoutModalProps> = ({ isOpen, toggleModal, userEmail }) => {
    useEffect(() => {
        if(isOpen) {
            recordEvent("Checkout Modal Opened", {})
        }
    }, [isOpen]);

    return (
        <Modal open={isOpen}
               onClose={toggleModal}
               sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        >
            <ModalDialog
                layout={ isMobile() ? "fullscreen":"center"}
                sx={ !isMobile()? { minWidth: "1400px", minHeight: "400px"}: null}
            >
                <Account userEmail={userEmail || ""} />
                <Typography level={"body-xs"}>No lock-in. Cancel your monthly subscription at any time.</Typography>
                <ModalClose />
            </ModalDialog>
        </Modal>
    );
};