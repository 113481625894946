import { Modal, Button, Sheet, ModalClose, ModalDialog, Typography, Divider, DialogTitle } from "@mui/joy";
import React, { useState, useEffect } from "react";
import NotesIcon from "@mui/icons-material/Notes";
import { recordEvent, isMobile } from "../../util/utils";

interface CaseNoteViewerProps {
    summary: {
        case_title_citation: string;
        judgement_date: string;
        court: string;
        judge: string;
        parties: {
            defendant: string;
            plaintiff: string;
        };
        issue: string;
        law: string;
        analysis: string;
        significance: string;
        conclusion: string;
    };
}

export const CaseNoteViewer: React.FC<CaseNoteViewerProps> = ({ summary }) => {
    const caseNote = summary;
    const [open, setOpen] = useState(false);
    const [isOnMobile, setIsOnMobile] = useState(isMobile());

    useEffect(() => {
        const handleResize = () => {
            setIsOnMobile(isMobile());
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleOpen = () => {
        recordEvent("case_note_viewed", { case_title_citation: caseNote.case_title_citation, court: caseNote.court });
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button startDecorator={<NotesIcon />} color={"primary"} variant={"plain"} size={"md"} onClick={handleOpen}>
                Case note
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <ModalDialog
                    sx={{
                        width: isOnMobile ? "100%" : "80%",
                        maxWidth: isOnMobile ? "100%" : "1000px",
                        margin: "0 auto",
                        height: isOnMobile ? "100%" : undefined,
                        maxHeight: isOnMobile ? "100%" : "90vh",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: isOnMobile ? 0 : undefined
                    }}
                >
                    <ModalClose />
                    <DialogTitle>
                        <Typography level={"body-xs"}>These notes were generated by AI.</Typography>
                    </DialogTitle>
                    <Sheet
                        sx={{
                            flexGrow: 1,
                            overflowY: "auto",
                            padding: isOnMobile ? "15px" : "30px",
                            gap: 1.5,
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Typography level={"h3"}>{caseNote.case_title_citation}</Typography>
                        <Typography level="body-sm">
                            <b>Date of Judgment:</b> {caseNote.judgement_date}
                        </Typography>
                        <Typography level="body-sm">
                            <b>Court:</b> {caseNote.court}
                        </Typography>
                        <Typography level="body-sm">
                            <b>Judge:</b> {caseNote.judge}
                        </Typography>
                        <Typography level="body-sm">
                            <b>Plaintiff:</b> {caseNote.parties?.plaintiff}
                        </Typography>
                        <Typography level="body-sm">
                            <b>Defendant:</b> {caseNote.parties?.defendant}
                        </Typography>
                        <Divider />
                        <Typography level="h4">Issue</Typography>
                        <Typography level="body-md">{caseNote.issue}</Typography>
                        <Typography level="h4">Law</Typography>
                        <Typography level="body-md">{caseNote.law}</Typography>
                        <Typography level="h4">Analysis</Typography>
                        <Typography level="body-md">{caseNote.analysis}</Typography>
                        <Typography level="h4">Significance</Typography>
                        <Typography level="body-md">{caseNote.significance}</Typography>
                        <Typography level="h4">Conclusion</Typography>
                        <Typography level="body-md">{caseNote.conclusion}</Typography>
                    </Sheet>
                </ModalDialog>
            </Modal>
        </div>
    );
};
