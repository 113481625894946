export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export type ChatAppRequestOverrides = {
    retrieval_mode?: RetrievalMode;
    semantic_ranker?: boolean;
    semantic_captions?: boolean;
    exclude_category?: string;
    skip?: number;
    top?: number;
    temperature?: number;
    prompt_template?: string;
    prompt_template_prefix?: string;
    prompt_template_suffix?: string;
    suggest_followup_questions?: boolean;
    use_oid_security_filter?: boolean;
    use_groups_security_filter?: boolean;
    filters : FilterRequest;
};

export type ResponseMessage = {
    content: string;
    role: string;
};

export type ResponseContext = {
    thoughts: string | null;
    data_points: string[];
    followup_questions: string[] | null;
};

export type ResponseChoice = {
    index: number;
    message: ResponseMessage;
    context: ResponseContext;
    session_state: any;
};

export type ChatAppResponseOrError = {
    conversation_id: string;
    choices?: ResponseChoice[];
    error?: string;
};

export type ChatAppResponse = {
    conversation_id: string;
    choices: ResponseChoice[];
    documents: any[];
};

export type ChatAppRequestContext = {
    overrides?: ChatAppRequestOverrides;
    chat_type?: string;
};

export type FilterRequest = {
    [key: string]: string[] | null;
}

export type ChatAppRequest = {
    conversation_id?:string;
    messages: ResponseMessage[];
    context?: ChatAppRequestContext;
    stream?: boolean;
    session_state: any;
};

export type CaseNotesSearchRequest = {
    search: string;
    context: ChatAppRequestContext;
}

export type Document = {
  before: string;
  cases_cited: string;
  category: string;
  court: string;
  date: string;
  sort_date:string;
  decision: string;
  decision_date: string;
  date_of_decision: string;
  doc_id: string;
  doc_type: "judgement";
  extract: string;
  id: string;
  jurisdiction: string;
  name: string;
  text: string;
  url: string;
  summary1?: string;
  summary_version?: number;
  categories?: string[];
  blurb?: string;
  most_cited?: boolean;
};
